import { Outlet } from 'react-router-dom'

import { Container, NavTabs, Stack, Text } from '../../../components'

export function Contribute() {
  const navigation = [
    { label: 'credit card', to: 'credit-card', text: 'Credit Card' },
    { label: 'Bank Transfer', to: 'bank-transfer', text: 'Bank Transfer' },
    // { label: 'apple-pay', to: 'apple-pay', text: 'Apple Pay' },
    { label: 'e-transfer', to: 'e-transfer', text: 'E-transfer' },
    { label: 'cheque', to: 'cheque', text: 'Cheque' },
    { label: 'daf-transfer', to: 'daf-transfer', text: 'DAF Transfer' },
    { label: 'securities', to: 'securities', text: 'Securities' },
  ]
  return (
    <>
      <Container maxWidth="lg" sx={{ mb: 0 }}>
        <Text.H1>Make a Contribution</Text.H1>
        <Stack>
          <NavTabs tabs={navigation} />
        </Stack>
      </Container>
      <Outlet />
    </>
  )
}
