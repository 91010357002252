import { gql } from '@apollo/client'

export const me = gql`
  query Me {
    me {
      id
      email
      name
      userEmails {
        id
        email
      }
      favouriteCharities {
        id
        accountName
        mailingAddress
        city
        province
      }
      charityUsers {
        id
        title
        firstLogin
        userId
        charityId
      }
      profile {
        id
        userType
        firstName
        lastName
        middleInitials
        corporateName
        phone
        address {
          lineOne
          lineTwo
          postalCode
          province
          city
          country
        }
      }
    }
  }
`

export const getAllUsers = gql`
  query allUsers {
    allUsers {
      id
      email
      name
      funds {
        name
      }
    }
  }
`

export const getUser = gql`
  query GetUser($userId: Float!) {
    getUser(id: $userId) {
      id
      name
      status
      charityUsers {
        charityInvite {
          status
        }
      }
    }
  }
`
