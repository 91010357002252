import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, CardBox, Stack, Container, Fields, InputAdornment, validations } from '../../../components' // Adjust the import path as necessary

export function PayButton({ customer }) {
  const { register, handleSubmit, watch, getValues, errors, setValue } = useForm({
    defaultValues: {
      amount: '13.00', // Default price
    },
  })

  const price = watch('amount') // Watch the amount field to get its current value

  //   useEffect(() => {
  //     window.addEventListener("message", (event) => {
  //       if (event.data === "getParams") {
  //         const params = { price, customer };
  //         window.ReactNativeWebView.postMessage(JSON.stringify(params));
  //       }
  //     });
  //   }, [price, customer]);

  const handlePay = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        price: getValues().amount,
      })
    )
  }

  const onSubmit = () => {
    handlePay()
  }

  console.log(getValues())

  return (
    <Container maxWidth="lg">
      <CardBox title="Pay with Apple Pay">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* <Fields.Text
                label="Amount"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                inputComponent: Fields.Amount,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: 0,
                },
                }}
                {...register('amount', {
                    ...{ min: { value: 1, message: 'Must be at least 1' } },
                    ...validations.required,
                    ...validations.number,
                })}
            /> */}
            <Fields.Text
              label="Amount"
              name="amount"
              error={false} // Add validation as necessary
              helperText=""
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              {...register('amount', {
                required: 'Amount is required',
                pattern: {
                  value: /^\d+(\.\d{1,2})?$/,
                  message: 'Invalid amount format',
                },
              })}
            />
            <Button type="submit" sx={{ padding: '10px 20px', fontSize: '16px' }}>
              Pay with Apple Pay
            </Button>
          </Stack>
        </form>
      </CardBox>
    </Container>
  )
}
