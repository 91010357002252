import { gql } from '@apollo/client'

export const myCreditCards = gql`
  query MyCreditCards {
    me {
      id
      creditCards {
        id
        details
        multiUse
        status
        default
        canDelete
      }
    }
  }
`
export const myLinkedBanks = gql`
  query MyLinkedBanks {
    me {
      id
      linkedBanks {
        id
        details
        canDelete
      }
    }
  }
`

export const iq11Url = gql`
  query Iq11Url($args: GetIq11IframeUrlInput!) {
    iq11Url(args: $args) {
      Success
      ErrorMessage
      EmbedURL
      IframeKey
    }
  }
`
