import { useQuery } from '@apollo/client'
import { AppBar, Box, Step, StepLabel, Stepper, Toolbar, Typography } from '@mui/material'
import { useEffect, useMemo, useReducer } from 'react'
import { Link, useParams } from 'react-router-dom'
import { mergeDeep, set, update } from 'timm'

import { NoMatch } from '..'
import { BlockQuote, CharityLogo, Container, LinearProgress, Stack, Text, Tooltip } from '../../components'
import { queries } from '../../graphql'
import { useAuth } from '../../hooks'
import { ConfirmStep } from './ConfirmStep'
import { DonorInfoStep } from './DonorInfoStep'
import { GiftAmountStep } from './GiftAmountStep'
import { PaymentStep } from './PaymentStep'
import { SignupSuccessModal } from './SignupSuccessModal'
import { SuccessStep } from './SuccessStep'

const steps = [{ label: 'Gift Amount' }, { label: 'Donor Info' }, { label: 'Payment' }, { label: 'Confirm' }]

export function GiftToCharity() {
  const { charityId } = useParams()
  const [{ isLoggedIn }] = useAuth()

  const [{ activeStep, modal, data: stepperState }, dispatchStep] = useReducer(
    (prevStore, { action, modal: updateModal, state: updateState }) => {
      let store = prevStore
      if (updateState) {
        store = update(store, 'data', (data) => mergeDeep(data, updateState))
      }
      if (updateModal) {
        store = update(store, 'modal', (oldModal) =>
          updateModal == null ? null : mergeDeep(oldModal ?? {}, updateModal)
        )
      }
      if (action === 'BACK_TO_GIFT_AMOUNT') {
        store = set(store, 'activeStep', 0)
      } else if (action === 'PREV') {
        store = update(store, 'activeStep', (step) => step - 1)
      } else if (action === 'NEXT') {
        store = update(store, 'activeStep', (step) => step + 1)
      }

      return store
    },
    useMemo(() => {
      const possibleDraftState = JSON.parse(localStorage.getItem('draft_donation_state') || 'null')
      const draftState = possibleDraftState?.forCharity === charityId ? possibleDraftState : {}

      return {
        activeStep: 0,
        modal: null,
        data: {
          giftDetails: draftState.giftDetails,
          payment: {
            paymentMethodType: draftState.payment?.paymentMethodType,
          },
        },
      }
    }, [charityId])
  )

  useEffect(() => {
    if (stepperState.success) {
      localStorage.removeItem('draft_donation_state')
    } else {
      localStorage.setItem(
        'draft_donation_state',
        JSON.stringify({
          forCharity: charityId,
          ...stepperState,
        })
      )
    }
  }, [charityId, stepperState])

  const { data: { charity } = {}, loading: charityLoading } = useQuery(queries.charities.charity, {
    variables: { charityId },
  })

  const { data: { me } = {}, loading: profileLoading } = useQuery(queries.user.me, { skip: !isLoggedIn })
  const { data: myFundData, loading: fundLoading } = useQuery(queries.funds.myFund, { skip: !isLoggedIn })
  const defaultFund = myFundData?.me?.fund

  if (charityLoading) return <LinearProgress />
  if (!charity) return <NoMatch title="Charity not found" />
  if (!charity.active) return <CharityNotActive charity={charity} />

  const stepProps = { isLoggedIn, me, defaultFund, charity, stepperState, dispatchStep }
  let stepContent
  if (stepperState.success) {
    stepContent = <SuccessStep {...stepProps} />
  } else if (modal) {
    stepContent = modal.type === 'SIGNUP_SUCCESS' && <SignupSuccessModal {...stepProps} params={modal.params} />
  } else {
    stepContent = (
      <>
        {activeStep === 0 && <GiftAmountStep {...stepProps} />}
        {activeStep === 1 && <DonorInfoStep {...stepProps} />}
        {activeStep === 2 && <PaymentStep {...stepProps} />}
        {activeStep === 3 && <ConfirmStep {...stepProps} />}
      </>
    )
  }

  return (
    <>
      {/* Hack to add a blue bar above the main content right below the logo header */}
      <AppBar
        position="absolute"
        sx={{
          top: `calc(39px + 2 * 1rem)`,
        }}
      >
        <Toolbar disableGutters />
      </AppBar>
      <AppBar position="sticky" color="default" sx={{ bgcolor: 'white' }}>
        {/* @note This was copied from navbar, but I'm not sure Container is supposed to be used this way */}
        <Container maxWidth="lg" sx={{ my: 0 }}>
          <Toolbar disableGutters>
            <Stepper activeStep={activeStep} sx={{ flexGrow: 1 }}>
              {steps.map((step, index) => (
                // eslint-disable-next-line react/no-array-index-key -- Steps are index based
                <Step key={index}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Toolbar>
        </Container>
      </AppBar>
      {!stepperState.success && !modal && <CharityHeader charity={charity} />}

      {stepContent}
    </>
  )
}

function CharityHeader({ charity }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', my: 2, mx: 'auto' }}>
      {/* <Tooltip title="† Minus any required bank processing fees">
        <Typography component="p" variant="h6">
          100%
          <Typography component="sup" color="primary.main">
            †
          </Typography>{' '}
          of your donation goes to:
        </Typography>
      </Tooltip> */}
      <Box sx={{ height: 140, m: 1 }}>
        <CharityLogo charityId={charity.id} categoryCode={charity.categoryCode} logo={charity.logo} />
      </Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        {charity.accountName}
      </Typography>
      <Typography variant="body2">Charity # {charity.id}</Typography>
    </Box>
  )
}

function CharityNotActive({ charity }) {
  const { emailAddress, contactPhoneNumber, websiteAddress } = charity

  return (
    <Stack>
      <BlockQuote>
        <Text.Body>
          This organization is no longer listed in the GiveWise charity database. Please contact the organization for
          more information.
        </Text.Body>
        {websiteAddress || emailAddress || contactPhoneNumber ? (
          <>
            {websiteAddress && (
              <Text.Body>
                <strong>Website: </strong> <Link href={`${websiteAddress}`}>{websiteAddress}</Link>
              </Text.Body>
            )}
            {emailAddress && (
              <Text.Body>
                <strong>Email: </strong> <Link href={`mailto:${emailAddress}`}>{emailAddress}</Link>
              </Text.Body>
            )}
            {contactPhoneNumber && (
              <Text.Body>
                <strong>Phone: </strong>{' '}
                <Link href={`tel:+${contactPhoneNumber?.replace('[^0-9]', '')}`}>{contactPhoneNumber}</Link>
              </Text.Body>
            )}
          </>
        ) : (
          <Text.Body>No contact information available</Text.Body>
        )}
      </BlockQuote>
    </Stack>
  )
}
