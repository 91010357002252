import { useQuery, useApolloClient } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { CharityPaylink } from '..'
import {
  Card,
  CardBox,
  Container,
  Icons,
  LinearProgress,
  Text,
  Row,
  Button,
  Box,
  CancelButton,
  useConfirm,
  Stack,
  Collapse,
  BlockQuote,
  Link,
} from '../../components'
import { mutations, queries } from '../../graphql'

export function CharityPortalDirectDeposit() {
  const apolloClient = useApolloClient()
  const { id: charityId } = useParams()
  const [isOpen, setIsOpen] = useState(false)

  const [, { setConfirm }] = useConfirm()
  const navigate = useNavigate()
  const { data: { getCharityBankAccounts } = {}, loading: loadingCharityBankAccounts } = useQuery(
    queries.charities.getCharityBankAccounts,
    {
      variables: { charityId },
    }
  )
  const [removeBankAccount] = useMutation(mutations.charities.removeCharityBankAccount, {
    refetchQueries: 'getCharityBankAccounts',
  })

  if (loadingCharityBankAccounts) {
    return <LinearProgress />
  }

  if (getCharityBankAccounts && !getCharityBankAccounts.length) {
    return (
      <Container maxWidth="lg">
        <Text.H1>Direct Deposit</Text.H1>
        <br />
        <Text.H7>Connect your charity&apos;s bank account to receive donations</Text.H7>
        <br />
        <br />
        <CardBox>
          <CharityPaylink showCharityName={false} />
        </CardBox>
      </Container>
    )
  }

  const message = (
    <Stack spacing={0}>
      <Text.Body>Please confirm that you want to remove the following bank account:</Text.Body>
      <Text.H7>{`${getCharityBankAccounts[0].details.institution} - ****${getCharityBankAccounts[0].details.last4}`}</Text.H7>
    </Stack>
  )
  const onClick = async (_event) => {
    setConfirm((prev) => ({
      ...prev,
      title: 'Confirm',
      message,
      mutation: mutations.charities.removeCharityBankAccount,
      success: 'Bank Account has been removed from your Charity profile',
      error: 'Failed to remove bank account',
      variables: { id: charityId, paymentMethodId: getCharityBankAccounts[0].id },
      onSuccess: async () => {
        // window.location.reload()
        await apolloClient.refetchQueries({
          include: ['Charity', 'getCharityBankAccounts'],
        })
      },
    }))
  }

  return (
    <Container maxWidth="lg">
      <Text.H1>Direct Deposit</Text.H1>
      <br />
      <Text.H7>You are set up to receive your donations to the following bank account</Text.H7>
      <br />
      <br />
      <Card
        sx={{
          padding: '25px',
          transition: 'transform 0.15s ease-in-out',
          '&:hover': { transform: 'scale3d(1.01, 1.01, 1)' },
        }}
      >
        <BlockQuote sx={{ pt: 1, pb: 1, margin: '10px' }}>
          <Row alignItems="center">
            <Button unstyled onClick={() => setIsOpen(!isOpen)}>
              <Text.Bold>FAQs</Text.Bold>
              {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
            </Button>
          </Row>
          <Collapse in={isOpen} unmountOnExit sx={{ mt: '0 !important' }}>
            <Text.Body>
              <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                <li>
                  <b>Does GiveWise store my banking information?</b> No. GiveWise does not store any of your banking
                  information at all. GiveWise uses a secure banking platform called VoPay and no banking information is
                  stored by us.
                </li>
                <li>
                  <b>How do I update my Direct Deposit information?</b> Under the Direct Deposit tab, choose “Remove”
                  from the right of the existing bank account. Once that is done, you will see the setup screen and a
                  new bank account can be entered.
                </li>
                {/* <li>
                    <b>
                      Where are do I find the details of the grant? How do I know where these funds are meant to be
                      directed?
                    </b>{' '}
                    Grant letters containing details of the grant, including donor information and designation of funds,
                    are sent out weekly when grants are processed. For a sample Grant Letter, see the{' '}
                    <Link
                      href="https://www.givewise.ca/application/files/8916/6880/7565/Grant_Letter_SAMPLE_FOR_WEBSITE_-_GiveWise.pdf"
                      target="_blank"
                    >
                      Sample Grant Letter
                    </Link>{' '}
                    on our website.
                  </li> */}
              </ul>
            </Text.Body>
          </Collapse>
        </BlockQuote>
        <Row>
          <Icons.BuildingColumns />
          <Text.H6>{getCharityBankAccounts[0].details.institution}</Text.H6>
        </Row>
        <Row sx={{ padding: '10px 0px' }}>
          <Text.H7>Account Number</Text.H7>
          <Text.Body>{`****${getCharityBankAccounts[0].details.last4}`}</Text.Body>
        </Row>
        <Row sx={{ padding: '15px 0px', display: 'flex', justifyContent: 'space-between' }}>
          <Text.Body sx={{ maxWidth: '800px' }}>
            If you&apos;d like to change your bank account, please remove the existing one and follow the on-screen
            instructions to add a new one.
          </Text.Body>
          <Button variant="outlined" color="error" onClick={onClick}>
            Remove
          </Button>
        </Row>
      </Card>
    </Container>
  )
}
