import { ListSubheader } from '@mui/material'

import { Menu, SidebarToggleButton } from '..'
import { Icons, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '../../../components'
import styles from './styles.module.css'

export function Sidebar(props) {
  return (
    <Menu
      {...props}
      sx={{
        marginTop: 0,
        backgroundColor: 'var(--light-grey)',
        '&.RaMenu-closed .MuiListItemText-root': { display: 'none' },
        '&.RaMenu-closed .MuiListSubheader-root': { visibility: 'hidden' },
        '& .MuiListItemText-root': { flex: '160px 0 0' },
        '& .MuiListItemButton-root': {
          padding: '6px 16px',
          '& .MuiListItemText-root span': {
            color: 'black !important',
            textDecoration: 'none !important',
          },
          '& .MuiListItemIcon-root': {
            color: 'var(--dark-blue)',
          },
        },
        '& .MuiListSubheader-root': {
          color: 'var(--darker-grey)',
          backgroundColor: 'transparent',
        },
        '& .MuiListItemButton-root.active': {
          backgroundColor: 'var(--white)',
        },
      }}
    >
      <List>
        <ListItem>
          <SidebarToggleButton className={styles.sidebarToggleButton} />
        </ListItem>

        <List subheader={<ListSubheader>Users</ListSubheader>}>
          <ListItemButton component={Link} to="User">
            <Tooltip title="Users">
              <ListItemIcon>
                <Icons.User />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton component={Link} to="MFund">
            <Tooltip title="Funds">
              <ListItemIcon>
                <Icons.Fund />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Funds" />
          </ListItemButton>
          <ListItemButton component={Link} to="ManagingUserOnFund">
            <Tooltip title="Roles">
              <ListItemIcon>
                <Icons.ManagingUserOnFund />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Roles" />
          </ListItemButton>
          <ListItemButton component={Link} to="CharityUser">
            <Tooltip title="CharityUsers">
              <ListItemIcon>
                <Icons.ManagingUserOnFund />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Charity Users" />
          </ListItemButton>
        </List>

        <List subheader={<ListSubheader>Grants</ListSubheader>}>
          <ListItemButton component={Link} to="Grant">
            <Tooltip title="Grants">
              <ListItemIcon>
                <Icons.Grant />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Grant Summary" />
          </ListItemButton>
          <ListItemButton component={Link} to="GrantRequest">
            <Tooltip title="Grant Requests">
              <ListItemIcon>
                <Icons.VolunteerActivism />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Grant Requests" />
          </ListItemButton>
          <ListItemButton component={Link} to="RecurrentGrantRequest">
            <Tooltip title="Recurring Grant Requests">
              <ListItemIcon>
                <Icons.RecurrentGrantRequest />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Recurring Grant Requests" />
          </ListItemButton>
        </List>

        <List subheader={<ListSubheader>Contributions</ListSubheader>}>
          <ListItemButton component={Link} to="Contribution">
            <Tooltip title="Contributions">
              <ListItemIcon>
                <Icons.Contribution />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Contributions" />
          </ListItemButton>
          <ListItemButton component={Link} to="RecurrentContribution">
            <Tooltip title="Recurring Contributions">
              <ListItemIcon>
                <Icons.RecurrentContribution />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Recurring Contributions" />
          </ListItemButton>
        </List>

        <List subheader={<ListSubheader>Share Funds</ListSubheader>}>
          <ListItemButton component={Link} to="DafTransfer">
            <Tooltip title="Share Funds">
              <ListItemIcon>
                <Icons.DafTransfer />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Share Funds" />
          </ListItemButton>
          <ListItemButton component={Link} to="RecurrentDafTransfer">
            <Tooltip title="Recurring Share Funds">
              <ListItemIcon>
                <Icons.RecurrentGrantRequest />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Recurring Share Funds" />
          </ListItemButton>
        </List>

        <List subheader={<ListSubheader>Other</ListSubheader>}>
          <ListItemButton component={Link} to="BalanceAdjustment">
            <Tooltip title="Balance Adjustments">
              <ListItemIcon>
                <Icons.BalanceAdjustment />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Balance Adjustments" />
          </ListItemButton>
          <ListItemButton component={Link} to="Charity">
            <Tooltip title="Charities">
              <ListItemIcon>
                <Icons.Charity />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Charities" />
          </ListItemButton>
          <ListItemButton component={Link} to="GenerosityFund">
            <Tooltip title="Generosity Funds">
              <ListItemIcon>
                <Icons.GenerosityWorks />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Generosity Funds" />
          </ListItemButton>
          <ListItemButton component={Link} to="CarouselItem">
            <Tooltip title="Carousel">
              <ListItemIcon>
                <Icons.Carousel />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Carousel" />
          </ListItemButton>
        </List>
      </List>
    </Menu>
  )
}
