import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { CircularProgress, Skeleton, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { mutations, queries } from '../../graphql'
import {
  Acknowledge,
  BlockQuote,
  Button,
  Collapse,
  Fields,
  Icons,
  Iframe,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Row,
  Stack,
  Text,
} from '..'
import { VOPAY_EMBED_URL } from '../../constants'
import { titleCase } from '../../utils'

export function Select({ register }) {
  const { data, loading } = useQuery(queries.paymentMethods.myLinkedBanks)
  const [isNew, setIsNew] = useState(false)
  const linkedBanks = data?.me?.linkedBanks

  /* after loading, if we have no saved accounts, then we're adding a new one  */
  useEffect(() => !loading && !linkedBanks && setIsNew(true), [loading, linkedBanks])

  if (loading) return <Skeleton width="30rem" height="56px" />

  return isNew ? (
    <NewAccount setIsNewBankAccount={linkedBanks.length && setIsNew} linkedBanks={linkedBanks} />
  ) : (
    <SavedAccounts register={register} setIsNewBankAccount={setIsNew} linkedBanks={linkedBanks} />
  )
}

export function FAQ() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <BlockQuote sx={{ pt: 1, pb: 1 }}>
      <Row alignItems="center">
        <Button unstyled onClick={() => setIsOpen(!isOpen)}>
          <Text.Bold>FAQs</Text.Bold>
          {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </Button>
      </Row>
      <Collapse in={isOpen} unmountOnExit sx={{ mt: '0 !important' }}>
        <Text.Body>
          <ul style={{ listStyle: 'none', lineHeight: 2 }}>
            <li>
              <b>Does GiveWise store any banking information?</b> No, GiveWise does not store any banking information.
              GiveWise uses a banking platform called VoPay which allows you to contribute to your fund directly from
              your linked bank accounts.
            </li>
            <li>
              <b>Why are my contributions showing in &ldquo;Pending&rdquo; state?</b> When you make a contribution
              directly from one of your linked bank accounts, you are beginning the transfer of funds. Banks will take
              some time to verify these transactions. When the transaction is verified by the bank, it will be be marked
              as &ldquo;Cleared&rdquo;, or &ldquo;Cancelled&rdquo; / &ldquo;Failed&rdquo; if the transaction could not
              be verified.
            </li>
            <li>
              <b>Can I use funds that are in the &ldquo;Pending&rdquo; state?</b> No. Once the contribution is marked in
              the &ldquo;Cleared&rdquo; state, the funds will be available in your Giving Wallet, will appear on the Tax
              Receipt, and will be available to gift to charities.
            </li>
            <li>
              <b>How long will my contribution take to clear the bank?</b> Your contribution will take up to 4 business
              days.
            </li>
          </ul>
        </Text.Body>
      </Collapse>
    </BlockQuote>
  )
}

export function SavedAccounts({ register, linkedBanks, setIsNewBankAccount }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [valueAs, _setValueAs] = useState(linkedBanks.length ? linkedBanks[0].id : '')
  const setValueAs = (text) => {
    const v = parseInt(text, 10)
    _setValueAs(v)
    return v
  }
  const onChange = ({ target: { value } }) => setValueAs(value)

  return (
    <Row justifyContent="space-between" alignItems="flex-start">
      <Fields.Text
        id="outlined-select-currency"
        select
        label="Bank Account"
        sx={{ maxWidth: '30rem' }}
        {...register('paymentMethodId', { setValueAs, onChange })}
        value={valueAs}
        defaultValue={valueAs}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icons.Account />
            </InputAdornment>
          ),
        }}
      >
        {linkedBanks.map(({ id, details: { institution, last4 } }) => (
          <MenuItem key={id} value={id}>
            {titleCase(institution)}: ****{last4}
          </MenuItem>
        ))}
      </Fields.Text>

      <Stack alignItems={`flex-${isMobile ? 'start' : 'end'}`}>
        <Stack width="auto">
          <Button variant="text" onClick={() => setIsNewBankAccount(true)}>
            Connect a new bank account
          </Button>
          <Button variant="text" to="/profile#manage-bank-accounts" style={{ marginTop: 0 }}>
            Manage Bank Accounts
          </Button>
        </Stack>
      </Stack>
    </Row>
  )
}

export function NewAccount({ setIsNewBankAccount, linkedBanks = [] }) {
  const { fundId } = useParams()
  const [showLoading, setShowLoading] = useState(null)

  const { data: iq11Data = {}, loading: iq11Loading } = useQuery(queries.paymentMethods.iq11Url, {
    variables: {
      args: {
        fundId: Number(fundId),
        showPadAgreementPrompt: true,
      },
    },
  })
  const { EmbedURL: embedUrl, IframeKey: iframeKey } = iq11Data?.iq11Url || {}

  const [addLinkedBank, { data: linkedBankData, error: linkedBankError, reset: linkedBankReset }] = useMutation(
    mutations.paymentMethods.addLinkedBank,
    {
      refetchQueries: [{ query: queries.paymentMethods.myLinkedBanks }],
    }
  )

  useEffect(() => {
    const messageHandler = (event = {}) => {
      const { data, origin } = event
      if (!data || origin !== VOPAY_EMBED_URL) return

      const { Step: step, Token: token, Institution: institution, AccountNumber: accountNumber } = data
      if (step !== 'LINK') {
        console.info('unknown iq11 message', step)
        return
      }

      addLinkedBank({
        variables: {
          data: {
            token,
            institution,
            last4: accountNumber?.slice(-4) ?? '',
          },
        },
      })
    }
    window.addEventListener('message', messageHandler)
    return () => window.removeEventListener('message', messageHandler)
  }, [])

  const Success = useCallback(
    () => (
      <Acknowledge.Success title="Thanks!" content="You can now contribute directly from your bank account securely">
        <Button onClick={() => setIsNewBankAccount(false)}>Contribute</Button>
      </Acknowledge.Success>
    ),
    []
  )

  const Error = useCallback(
    () => (
      <Acknowledge.Error title="Uh Oh!" content="We could not save your bank account" message={linkedBankError.message}>
        <Button onClick={linkedBankReset}>Back</Button>
      </Acknowledge.Error>
    ),
    [linkedBankError]
  )

  if (iq11Loading) return <LinearProgress />

  if (linkedBankData && showLoading === null) {
    setShowLoading(true)
    setTimeout(() => setShowLoading(false), 5000)
  }

  if (showLoading) {
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <CircularProgress />
        {linkedBanks?.length > 0 && (
          <Button sx={{ alignSelf: 'end' }} variant="text" onClick={() => setIsNewBankAccount(false)}>
            Use a previously linked bank account
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Acknowledge.Switch success={!!linkedBankData && <Success />} error={!!linkedBankError && <Error />}>
        {embedUrl && (
          <Iframe title="Vopay Bank Link" embedUrl={embedUrl} iframeKey={iframeKey} style={{ height: '675px' }} />
        )}
        {linkedBanks?.length > 0 && (
          <Button sx={{ alignSelf: 'end' }} variant="text" onClick={() => setIsNewBankAccount(false)}>
            Use a previously linked bank account
          </Button>
        )}
      </Acknowledge.Switch>
    </Stack>
  )
}
