import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { Confirm } from 'react-admin'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, CardBox, CardActions, CardContent, Icons, Stack, Text, useAlert } from '../../components'
import { queries, mutations } from '../../graphql'

export function ManageBankAccounts(props) {
  const { manageBankAccountsRef } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [cannotDeleteOpen, setCannotDeleteOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const bankAccountsResponse = useQuery(queries.paymentMethods.myLinkedBanks)
  const myFund = useQuery(queries.funds.myFund)

  const [mutate, { isLoading }] = useMutation(mutations.paymentMethods.deleteBankAccount, {
    onCompleted: () => {
      setAlert({ message: `Removed payment method.`, severity: 'success' })
      setDeletingId(null)
    },
    onError: (newErrors) => {
      setAlert({ message: `An error occured.`, severity: 'error' })
      setDeletingId(null)
    },
    refetchQueries: [{ query: queries.paymentMethods.myLinkedBanks }],
  })

  const bankAccounts = bankAccountsResponse.data?.me?.linkedBanks
  const hasBankAccounts = bankAccounts && bankAccounts.length

  const handleDelete = () => {
    mutate({
      variables: {
        data: {
          ids: [deletingId],
        },
      },
    })

    setOpen(false)
  }

  const handleConfirm = (id) => {
    setOpen(true)
    setDeletingId(id)
  }
  const handleDialogClose = () => {
    setOpen(false)
    setCannotDeleteOpen(false)
    setDeletingId(null)
  }

  return (
    <Stack ref={manageBankAccountsRef}>
      <CardBox>
        <Stack direction="row">
          <Text.H4>Bank Accounts</Text.H4>
        </Stack>
        <br />
        <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
          {hasBankAccounts ? (
            bankAccounts.map((ba) => {
              const srcObj = ba.details

              return (
                <Card
                  key={ba.id}
                  variant="outlined"
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    margin: 10,
                    backgroundColor: 'lightblue',
                    borderColor: 'lightblue',
                  }}
                >
                  <CardContent>
                    <Text.H5>{`${srcObj.institution}`}</Text.H5>
                    <br />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Text.Body>{`••••••••${srcObj.last4}`}</Text.Body>
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="error"
                      variant="text"
                      onClick={ba.canDelete ? () => handleConfirm(ba.id) : () => setCannotDeleteOpen(true)}
                    >
                      <Icons.DeleteForever fontSize="small" />
                      Remove
                    </Button>
                  </CardActions>
                </Card>
              )
            })
          ) : (
            <Text.H5>
              No saved bank accounts.{' '}
              <Link to={`/funds/${myFund.data?.me?.fund.id}/contribute/bank-transfer`}>Add Funds</Link> to save a bank
              account.
            </Text.H5>
          )}
        </Stack>
        <br />
        <Dialog
          open={cannotDeleteOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This bank account cannot be removed at the moment because it is being used for currently scheduled or
              recurring contributions.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
            <Button onClick={() => navigate(`/funds/${myFund.data?.me?.fund.id}/activity/giving-wallet`)} autoFocus>
              Go to my activity
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={open}
          loading={isLoading}
          title="Remove Bank Account"
          content="Are you sure you want to remove this bank account from your profile?"
          onConfirm={handleDelete}
          onClose={handleDialogClose}
          cancel="Cancel"
          confirm="Yes, remove it"
          confirmColor="secondary"
        />
        <Alert {...alertProps} />
      </CardBox>
    </Stack>
  )
}
