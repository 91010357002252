import { ListItemButton, ListItemIcon, Radio, Typography } from '@mui/material'
import { useController } from 'react-hook-form'

/**
 * List item to use as a Radio selector
 */
export function RadioListItem({
  control,
  name,
  value,
  beforeChange,
  required,
  hideRadio = false,
  icon,
  label,
  helperText,
}) {
  const { field } = useController({
    control,
    name,
    rules: {
      required,
    },
  })

  const radioProps = {
    ...field,
    value,
    onChange: (e) => {
      if (e.target.checked) {
        beforeChange?.()
        field.onChange(value)
      }
    },
    checked: field.value === value,
    required,
  }

  return (
    <ListItemButton
      component="label"
      sx={{
        pl: 0,
        borderTop: '1px solid',
        borderTopColor: 'divider',
      }}
      tabIndex={-1}
    >
      <Radio
        {...radioProps}
        sx={(theme) => ({
          ...(hideRadio
            ? {
                opacity: 0,
                transition: theme.transitions.create('opacity', { duration: theme.transitions.duration.short }),
                '.MuiButtonBase-root:hover &': { opacity: 1 },
                '&:focus': { opacity: 1 },
              }
            : null),
        })}
      />
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography variant="body1" component="span" display="block" minWidth="15ch" pr={2}>
        {label}
      </Typography>
      <Typography variant="body2" color="text.secondary" display="block" flexGrow={1}>
        {helperText}
      </Typography>
    </ListItemButton>
  )
}
