import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import { Container, Stack } from '../../components'
import { toAmountString } from '../../utils'
import { PaymentMethodsMeta } from './payment'

export function SuccessStep({ charity, me, stepperState }) {
  const { fundId, paymentMethodType, isRecurring, processingDate, grantTotal } = stepperState.success

  const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}

  // const [requestPasswordReset, { loading, data, reset: resetMutation }] = useMutation(
  //   mutations.user.requestPasswordReset,
  //   {
  //     onCompleted: () => {},
  //   }
  // )

  return (
    <Container maxWidth="sm" sx={{ flex: 1, py: 2, display: 'flex', flexDirection: 'column' }}>
      <Stack direction="column" flexGrow={1} justifyContent="center">
        <Typography variant="h2">
          Thank you! Your gift of {toAmountString(grantTotal)} to {charity.accountName} is now{' '}
          {isRecurring || processingDate ? 'scheduled' : 'pending'}.
        </Typography>

        {PaymentMethodMeta.SuccessUI && <PaymentMethodMeta.SuccessUI me={me} {...stepperState.success} />}

        <Typography>
          You can check on the status of your gift at anytime from the{' '}
          <Link to={`/funds/${fundId}/activity`}>Activity</Link> page.
        </Typography>
        <Typography>
          If you would like to make additional donations, get documents for tax credits, or manage monthly donations you
          can return to this GiveWise site.
        </Typography>

        {/* @note This is not needed until the Donor Info step has sign-up embedded */}
        {/* <Divider direction="horizontal" />
  
		  <Stack direction="column" spacing={2} alignItems="center" sx={{ mt: 4 }}>
			<Text.Body>
			  You’re almost there! Add a password to your Giving Fund so that you can log in when you return.{' '}
			</Text.Body>
  
			<Button color="info" onClick={() => requestPasswordReset(me.email)}>
			  Add Password
			</Button>
		  </Stack> */}
      </Stack>
    </Container>
  )
}
