import { useQuery, useApolloClient } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useState, useEffect } from 'react'
import { useNotify } from 'react-admin'
import { useController, useForm } from 'react-hook-form'
import { error } from 'logrocket'
import { ThemeProvider } from '@mui/material'

import {
  Card,
  CardBox,
  Container,
  Icons,
  LinearProgress,
  Text,
  Row,
  Button,
  Box,
  CancelButton,
  useConfirm,
  Stack,
  Collapse,
  BlockQuote,
  Link,
  useAlert,
} from '../../components'
import { mutations, queries } from '../../graphql'
import { InviteCharityButton } from '../../management/charities/actions/send-paylink-common'
import { theme } from '../../management/mui.config'

function formatAMPM(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours ?? 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes}${ampm}`
  return strTime
}

function formatDate(date) {
  const month = date.getMonth() + 1 // months are 0-based in JS
  const day = date.getDate()
  const year = date.getFullYear()
  const formattedDate = `${month}/${day}/${year} at ${formatAMPM(date)}`
  return formattedDate
}

// Non admin version of invite-charity.jsx
export function CharityPortalInviteUsers() {
  const { id: charityId } = useParams()
  // useNotify seems to only work on the admin page and so has been replaced with setAlert
  const notify = useNotify()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const { data: { getCharityInvitesCharityPortal: charityPortalInvitesCharityPortal } = {}, loading } = useQuery(
    queries.charities.getCharityInvitesCharityPortal,
    {
      variables: {
        charityId,
      },
    }
  )
  const [revokeInviteCharityPortal, { revokeLoading }] = useMutation(mutations.charities.revokeInviteCharityPortal, {
    onCompleted: () => {
      setAlert({
        message: 'Invite Revoked',
        severity: 'success',
      })
    },
    onError: (_error) => {
      console.log(error)
      setAlert({ message: 'Error: Could not revoke invite', severity: 'warning' })
    },
    refetchQueries: ['getCharityInvitesCharityPortal'],
  })
  const [inviteCharityToSignUpCharityPortal, { isLoading }] = useMutation(
    mutations.charities.inviteCharityToSignUpCharityPortal,
    {
      onCompleted: () => {
        setAlert({
          message: 'Invite Sent!',
          severity: 'success',
        })
      },
      onError: (_error) => {
        console.log(_error.message)
        if (_error.message.includes('Duplicate existing email')) {
          setAlert({
            message:
              'This email address may already exist within GiveWise. Please choose another email address or contact us at grants@givewise.ca',
            severity: 'warning',
          })
        } else {
          setAlert({ message: 'Error: Invite email not sent', severity: 'warning' })
        }
      },
      refetchQueries: ['getCharityInvitesCharityPortal'],
    }
  )

  const { control, getValues, isSubmitted, setValue } = useForm()
  const emailControl = useController({ name: 'email', control })
  const noteControl = useController({ name: 'note', control })

  useEffect(() => {
    setValue('email', '')
  }, [])

  const handleConfirm = () => {
    const email = getValues('email')
    const note = getValues('note') ?? ''
    inviteCharityToSignUpCharityPortal({
      variables: {
        charityId,
        email,
        note,
      },
    })
    setOpen(false)
  }

  const handleRevoke = (inviteId) => {
    revokeInviteCharityPortal({
      variables: {
        id: parseFloat(inviteId),
      },
    })
  }

  const statuses = {
    1: 'Invited',
    2: 'Accepted',
    3: 'Revoked',
  }

  if (charityPortalInvitesCharityPortal && charityPortalInvitesCharityPortal.length > 0) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Text.H1>Invite Users to the Charity Portal</Text.H1>
          <br />

          <CardBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'flex-start',
              padding: '15px',
              maxWidth: '750px',
              borderRadius: '10px',
            }}
          >
            <Text.H6>Charity Portal</Text.H6>

            {charityPortalInvitesCharityPortal.map((invite) => {
              const isInvited = invite.status === '1'
              const isAccepted = invite.status === '2'
              const isRevoked = invite.status === '3'

              return (
                <Row sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Text.H7>{`Status: ${statuses[invite.status]}`}</Text.H7>
                    <Text.H7>{`User: ${invite.user.email}`}</Text.H7>
                    <Text.Caption sx={{ color: 'grey' }}>
                      {`Invited on ${formatDate(new Date(invite.sentAt))}`}
                    </Text.Caption>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    {isInvited ? (
                      <Button
                        onClick={() => handleRevoke(invite.id)}
                        variant="outlined"
                        sx={{ marginLeft: '10px', maxWidth: '100px', margin: '10px' }}
                      >
                        Revoke
                      </Button>
                    ) : (
                      ''
                    )}
                  </Box>
                </Row>
              )
            })}

            <InviteCharityButton
              label="Invite another user"
              disabled={isLoading}
              loading={isLoading}
              handleClick={handleClick}
              isOpen={open}
              handleDialogClose={handleDialogClose}
              handleConfirm={handleConfirm}
              emailControl={emailControl}
              noteControl={noteControl}
              isSubmitted={isSubmitted}
            />
            <Alert {...alertProps} />
          </CardBox>
        </Container>
      </ThemeProvider>
    )
  }
  return (
    <Container maxWidth="lg">
      <Text.H1>Invite Users to the Charity Portal</Text.H1>
      <br />

      <InviteCharityButton
        label="Invite Charity To Sign Up"
        disabled={isLoading}
        loading={isLoading}
        handleClick={handleClick}
        isOpen={open}
        handleDialogClose={handleDialogClose}
        handleConfirm={handleConfirm}
        emailControl={emailControl}
        noteControl={noteControl}
        isSubmitted={isSubmitted}
      />
    </Container>
  )
}
