import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  LinearProgress,
  Stack,
  Scheduled,
  Recurring,
  useAlert,
  useDialog,
  validations,
} from '../../../components'
import * as Bank from '../../../components/payment/bank-transfer'
import { fromAmountField } from '../../../utils'

export function BankTransfer() {
  const {
    loading,
    data: { me: { linkedBanks = [] } = {} } = {},
    refetch,
  } = useQuery(queries.paymentMethods.myLinkedBanks)
  const [isNewBankAccount, setIsNewBankAccount] = useState(false)

  useEffect(() => {
    refetch()
    if (loading || isNewBankAccount) return
    if (!linkedBanks || linkedBanks?.length < 1) {
      setIsNewBankAccount(true)
    }
  }, [loading, linkedBanks, isNewBankAccount, setIsNewBankAccount])

  if (loading) return <LinearProgress />

  return (
    <Container maxWidth="lg">
      <CardBox title="Bank Transfer">
        <Stack spacing={4}>
          <Bank.FAQ />
          {isNewBankAccount ? (
            <Bank.NewAccount setIsNewBankAccount={setIsNewBankAccount} linkedBanks={linkedBanks} />
          ) : (
            <BankTransferForm setIsNewBankAccount={setIsNewBankAccount} linkedBanks={linkedBanks} />
          )}
        </Stack>
      </CardBox>
    </Container>
  )
}

function BankTransferForm({ setIsNewBankAccount, linkedBanks = [] }) {
  const { fundId } = useParams()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
      isRecurring: false,
      recurringInterval: 'monthly',
    },
  })
  const closeSuccessDialog = () => {
    closeDialog()
    resetForm()
  }
  const [createContribution, { data, loading }] = useMutation(mutations.contributions.createContribution, {
    refetchQueries: [
      { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
      { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
      { query: queries.paymentMethods.myLinkedBanks },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      openDialog()
    },
    onError: (e) => {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
      resetForm()
    },
  })
  const { errors = {} } = formState
  const onSubmit = async ({ amount, paymentMethodId, isRecurring, recurringInterval, processingDate }) => {
    createContribution({
      variables: {
        data: {
          paymentMethodId: Number(paymentMethodId),
          fundId: Number(fundId),
          amount: fromAmountField(amount),
          isRecurring,
          recurringInterval,
          processingDate,
        },
      },
    })
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2}>
          <Fields.Text
            label="Amount"
            name="amount"
            error={!!errors?.amount}
            helperText={errors?.amount?.message}
            InputProps={{
              inputComponent: Fields.Amount,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                setValue: (value) => setValue('amount', value),
                getValue: () => getValues('amount'),
                defaultValue: 0,
              },
            }}
            {...register('amount', {
              ...{ min: { value: 1, message: 'Must be at least 1' } },
              ...validations.required,
              ...validations.number,
            })}
          />
          <Bank.SavedAccounts register={register} linkedBanks={linkedBanks} setIsNewBankAccount={setIsNewBankAccount} />
          <Scheduled register={register} errors={errors} control={control} getValues={getValues} setValue={setValue} />
          <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
          <Button disabled={loading} type="submit" sx={{ alignSelf: 'flex-end' }}>
            Submit
          </Button>
          <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
        </Stack>
      </form>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" content="You have successfully created a contribution.">
          <Button to={`/funds/${fundId}/activity/giving-wallet`}>Done</Button>
        </Acknowledge.Success>
      </Dialog>
    </>
  )
}
