import { Box, Alert as MuiAlert, AlertTitle as MuiAlertTitle, Link as MuiLink } from '@mui/material'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import invariant from 'invariant'

import { Button, Container, ContentBox, Link, Stack } from '../../components'
import { PAYMENT_TYPE } from '../../constants'
import { PaymentMethodRadioList } from './PaymentMethodRadioList'
import { PaymentMethodsMeta } from './payment'

function getAmountLabel(amountType) {
  if (amountType === 'securities') return 'list of securities'
  return 'cash amount'
}

export function PaymentStep({ me, defaultFund, stepperState, dispatchStep }) {
  const paymentMethodApi = useRef()
  const { giftDetails } = stepperState

  const { register, handleSubmit, formState, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...stepperState?.payment,
      paymentMethodType: stepperState?.payment?.paymentMethodType ?? PAYMENT_TYPE.card,
    },
  })
  const { isSubmitting, errors = {} } = formState

  const onSubmit = async (values) => {
    const { paymentMethodType, ...payment } = values

    const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}
    invariant(
      PaymentMethodMeta.getPaymentValues,
      `Payment Method type ${paymentMethodType} is missing getPaymentValues`
    )

    const paymentMethodAmountType = PaymentMethodMeta.amountType ?? 'amount'
    if (giftDetails.amountType !== paymentMethodAmountType) {
      // eslint-disable-next-line no-console
      console.warn(
        `Blocked navigation to next step due to giftDetails.amountType (${giftDetails.amountType}) not matching payment method amountType (${paymentMethodAmountType})`
      )
      return
    }

    dispatchStep({
      action: 'NEXT',
      state: {
        giftDetails: {
          amountType: PaymentMethodMeta.amountType ?? 'amount',
          ...(PaymentMethodMeta.supportsRecurring === false ? { isRecurring: false } : {}),
        },
        payment: await PaymentMethodMeta.getPaymentValues({
          paymentMethodApi,
          values: { paymentMethodType, ...payment },
        }),
      },
    })
  }

  const paymentMethodType = watch('paymentMethodType')
  const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}
  const paymentMethodAmountType = PaymentMethodMeta.amountType ?? 'amount'

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ display: 'contents', border: 0, p: 0, m: 0 }}>
          <Stack spacing={2}>
            {/* Small fee applies (2.20% + $0.30 per transaction), but it&apos;s quick and convenient.
					Enjoy a hassle-free way to give with no fees */}

            <ContentBox border>
              <PaymentMethodRadioList control={control} setValue={setValue} fund={defaultFund} required />

              {giftDetails.amountType !== paymentMethodAmountType && (
                <MuiAlert severity="error" sx={{ my: 4 }}>
                  <MuiAlertTitle>
                    Changing the Gift Amount is required to change to a {PaymentMethodMeta.label} payment!
                  </MuiAlertTitle>
                  The previously inputted {getAmountLabel(giftDetails.amountType)} cannot be used by the{' '}
                  {PaymentMethodMeta.label} payment method. Please{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- This will become a link later */}
                  <Link
                    onClick={() =>
                      dispatchStep({
                        action: 'BACK_TO_GIFT_AMOUNT',
                        state: {
                          payment: {
                            paymentMethodType,
                          },
                        },
                      })
                    }
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    return to the Gift Amount step
                  </Link>{' '}
                  and enter a {getAmountLabel(paymentMethodAmountType)} to continue. All your previous information is
                  saved, so you can quickly make this change and continue.
                </MuiAlert>
              )}

              {PaymentMethodMeta.supportsRecurring === false && stepperState.giftDetails.isRecurring && (
                <MuiAlert severity="warning" sx={{ my: 4 }}>
                  <MuiAlertTitle>Continuing with a one-time payment</MuiAlertTitle>
                  This payment method does not support recurring payments and so your gift will change to a one-time
                  donation.
                </MuiAlert>
              )}

              {PaymentMethodMeta.PaymentUI && (
                <PaymentMethodMeta.PaymentUI
                  ref={paymentMethodApi}
                  {...{ me, defaultFund, register, control, formState }}
                />
              )}
            </ContentBox>

            <Stack direction="row" spacing={2}>
              <Button type="button" onClick={() => dispatchStep({ action: 'PREV' })} disabled={isSubmitting}>
                Back
              </Button>
              <Box flexGrow={1} />
              <Button type="submit" disabled={isSubmitting}>
                Next
              </Button>
            </Stack>
          </Stack>
        </fieldset>
      </form>
    </Container>
  )
}
