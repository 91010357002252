import { forwardRef, useImperativeHandle } from 'react'

import { BlockQuote, Fields, Stack, Text } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef(({ me, defaultFund, register }, ref) => {
  useImperativeHandle(ref, () => ({}))

  const name = me.name ?? ''
  const { number } = defaultFund

  return (
    <Stack spacing={2}>
      <Fields.Text
        label="Name of the Foundation/DAF"
        name="foundationDAFName"
        required
        {...register('foundationDAFName', {
          required: 'This field is required',
          defaultValue: '',
        })}
      />

      <Text.Body>
        Request that the Donor Advised Fund (DAF) or Foundation transfer funds to your Giving Fund after confirming your
        gift.
      </Text.Body>
      <BlockQuote>
        <Text.Bold>GiveWise Foundation Canada</Text.Bold>
        <Text.Body>
          <strong>Charity Number</strong> #701032526 RR 0001
        </Text.Body>
      </BlockQuote>
      <Text.Body>
        Additionally, please include your full name and your Giving Fund number in the transfer request. You can copy
        and paste the account number below.
      </Text.Body>
      <BlockQuote>
        <Text.Bold>GiveWise Giving Fund #{number}</Text.Bold>
        <Text.Body>
          <strong>Donor Name:</strong> {name}
        </Text.Body>
      </BlockQuote>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails, payment }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContributionRecord,
      variables: {
        fundId,
        amount: amounts.total,
        paymentType: 8,
        description: payment.foundationDAFName,
        taxReceipted: false,
      },
      refetchQueries: [{ query: queries.funds.myFundActivity, variables: { fundId } }],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.createContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, fund, payment, grantTotal }) {
  const name = me.name ?? ''
  const { number } = fund

  return (
    <>
      <p>
        You will need to contact {payment.foundationDAFName} to request the transfer of {toAmountString(grantTotal)}.
        When submitting this request, please include the following information:
      </p>
      <p>
        <strong>Transfer Funds To:</strong>
        <br />
        GiveWise Foundation Canada
        <br />
        Charity Number #701032526RR0001
      </p>
      <p>
        <strong>Please Request Funds Be Credit To:</strong>
        <br />
        GiveWise Giving Fund #{number}
        <br />
        Donor Name: {name}
      </p>
    </>
  )
}
