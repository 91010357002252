import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  Acknowledge,
  BlockQuote,
  Box,
  Button,
  CardBox,
  Collapse,
  Container,
  Dialog,
  Fields,
  Icons,
  InputAdornment,
  Link,
  Row,
  Stack,
  Text,
  insufficientFundsExtraMessage,
  longWait,
  useAlert,
  useDialog,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { fromAmountField, parseAndSetFormErrors, toAmountString } from '../../utils'

export function ShareFundsWithOthers() {
  const [isOpen, setIsOpen] = useState(false)
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const { fundId } = useParams()
  const {
    register,
    handleSubmit,
    formState,
    setError,
    getValues,
    setValue,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [initiateDafTransfer, { data: mutationData, error: mutationError, loading: mutationLoading }] = useMutation(
    mutations.dafTransfers.initiateDafTransfer,
    {
      onCompleted: () => {
        openDialog()
      },
      onError: (serverErrors) => {
        parseAndSetFormErrors(serverErrors, setError)
        setAlert({
          timeout: longWait,
          message: 'Gift failed',
          error: serverErrors?.message,
          extraMessage: serverErrors?.message.includes('insufficient') ? insufficientFundsExtraMessage(fundId) : '',
          severity: 'error',
        })
      },
      refetchQueries: [
        { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
        { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
      ],
    }
  )
  const onSubmit = async (data) => {
    if (!fundId) setAlert({ message: 'Could not send gift', severity: 'error' })
    const { email, recipientName, amount, description } = data
    await initiateDafTransfer({
      variables: {
        data: {
          sentTo: email,
          recipientName,
          amount: fromAmountField(amount),
          description,
          fundId: Number(fundId),
          type: 3,
        },
      },
    })
  }
  const closeSuccessDialog = () => {
    resetForm()
    closeDialog()
  }
  return (
    <Container maxWidth="lg">
      <Stack spacing={4}>
        <Row sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
          <Stack>
            <Text.H4>Share Funds With Others</Text.H4>
          </Stack>
        </Row>
        <CardBox>
          <Stack>
            <BlockQuote sx={{ pt: 1, pb: 1 }}>
              <Row alignItems="center">
                <Button unstyled onClick={() => setIsOpen(!isOpen)}>
                  <Text.Bold>FAQs</Text.Bold>
                  {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                </Button>
              </Row>
              <Collapse in={isOpen} unmountOnExit sx={{ mt: '0 !important' }}>
                <Text.Body>
                  <ul style={{ listStyle: 'none', lineHeight: 2 }}>
                    <li>
                      <b>Who can I share funds with?</b> You can share funds with anyone with an email address. Those
                      who have not created a GiveWise Giving Fund will need to do so before the funds can be accepted.
                    </li>
                    <li>
                      <b>Where can I see the gifts I have shared with others?</b> All shared funds will appear in the{' '}
                      <Link to={`/funds/${fundId}/activity/giving-wallet`}>Giving Wallet activity feed</Link> as soon as
                      they are initiated. This is where you will also be able to track the status of the shared funds.
                    </li>
                    <li>
                      <b>What can the recipient do with the shared funds?</b> Recipients can give funds to any
                      Registered Canadian Charity or Share With Others for the same purpose.
                    </li>
                    <li>
                      <b>Can I cancel previously shared funds?</b> Yes, you will be able to cancel any previously shared
                      funds that have not yet been accepted by the recipient. This can be done through in the{' '}
                      <Link to={`/funds/${fundId}/activity/giving-wallet`}>Giving Wallet activity feed</Link>.
                    </li>
                    <li>
                      <b>What happens if my shared funds are not accepted?</b> Any shared funds that have not been
                      accepted by the recipient after 30 days will be returned to your Giving Fund.
                    </li>
                  </ul>
                </Text.Body>
              </Collapse>
            </BlockQuote>
            <Box sx={{ width: '100%' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                  <Text.Bold>Recipient</Text.Bold>
                  <Row>
                    <Fields.Text name="recipientName" type="text" label="Name" {...register('recipientName')} />
                  </Row>
                  <Fields.Text
                    type="text"
                    name="email"
                    label="Email *"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    {...register('email', { ...validations.required, ...validations.email })}
                  />
                  <Text.Bold>Share</Text.Bold>
                  <Fields.Text
                    label="Amount"
                    name="amount"
                    error={!!errors?.amount}
                    helperText={errors?.amount?.message}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      inputComponent: Fields.Amount,
                      inputProps: {
                        setValue: (value) => setValue('amount', value),
                        getValue: () => getValues('amount'),
                        defaultValue: '',
                      },
                    }}
                    {...register('amount', {
                      ...{ min: { value: 1, message: 'Must be at least 1' } },
                      ...validations.required,
                      ...validations.number,
                    })}
                  />
                  <Fields.Text
                    label="Note to Recipient"
                    name="description"
                    error={!!errors?.description}
                    helperText={errors?.description?.message}
                    multiline
                    rows={4}
                    {...register('description')}
                  />
                  <Row alignSelf="flex-end" justifyContent="flex-end">
                    <Button type="submit" disabled={isSubmitting || mutationLoading}>
                      Send
                    </Button>
                  </Row>
                  <Alert {...alertProps} />
                </Stack>
              </form>
            </Box>
          </Stack>
        </CardBox>
      </Stack>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success
          title="Success!"
          content={
            <Text.Body>
              <b>Amount</b>: {toAmountString(mutationData?.initiateDafTransfer?.amount)}
              <br />
              <b>To</b>: {mutationData?.initiateDafTransfer?.sentTo}
            </Text.Body>
          }
        >
          <Button to={`/funds/${fundId}`}>Done</Button>
        </Acknowledge.Success>
      </Dialog>
    </Container>
  )
}
